import React from "react";
import Layout from "../components/layout";
import Helmet from "react-helmet";
import image from "../static/biocon_small.jpg";

export default () => (
  <Layout>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Profil | MUDr. Rastislav Šušol</title>
    </Helmet>

    <div id="page" class="seven columns">
      <div className="inner">
        <div id="page-header">
          <h1 id="page-title" className="title">
            Služby
          </h1>
        </div>

        <div id="content" className="content clearfix">
          <h2>Poskytujeme</h2>
          <ul>
            <li>
              urologické vyšetření po předchozím objednání, v akutních případech
              ihned
            </li>
            <li>
              ultrazvukové vyšetření horních a dolních močových cest, zevního
              genitálu, včetně dopplerovského vyš.
            </li>
            <li>transrektální ultrazvukové vyšetření endokavitální sondou.</li>
            <li>uroflowmetrie</li>
            <li>cystoskopie flexibilním ( ohebným ) cystoskopem</li>
            <li>biopsie prostaty pod UZ kontrolou</li>
            <li>rehabilitace svalů pánevního dna přístrojem Biocon 2000 W</li>
            <li>
              provádění operací na urologickém oddělení Svitavské nemocnice a.s.
            </li>
            <li>
              zajištění náročných operací na spolupracujících pracovištích ( v
              Masarykově onkologickém ústavu v Brně nebo urologickém oddělení
              Nemocnice u Sv. Anny v Brně )
            </li>
          </ul>

          <h2>Máme smluvní vztah s pojišťovnami</h2>
          <ul>
            <li>Všeobecná zdravotní pojišťovna - 111</li>
            <li>Pojišťovna ministerstva vnitra - 211</li>
            <li>Oborová zdravotní poj. bank a pojišťoven - 207</li>
            <li>Vojenská zdravotní pojišťovna - 201</li>
            <li>Česká průmyslová zdravotní pojišťovna - 205</li>
          </ul>

          <p>Přijímáme klienty všech pojišťoven.</p>
        </div>
      </div>
    </div>
    <div id="right" className="five columns">
      <div className="region region-sidebar-second">
        <div id="block-block-2" className="greenblock block block-block">
          <div className="inner">
            <h5 className="title">Biocon 2000 W</h5>
            <div className="content">
              <p>
                <img alt="" src={image} style={{ width: "100%" }} />
              </p>
              <p>
                <strong>
                  Rehabilitujte svaly pánevního dna, které jsou uvolněné a
                  pokleslé.
                </strong>
              </p>
              <p>
                <strong>
                  Sezení probíhá na křesle BIOCON 2000W, je příjemné,
                  nebolestivé a působí přes šaty.
                </strong>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);
